




import { defineComponent } from "@vue/composition-api";
import { Image } from "vant";

export default defineComponent({
  components: {
    [Image.name]: Image
  },
  data() {
    return {
      src: ""
    };
  },
  created() {
    this.src = decodeURIComponent(this.$route.query.src as string);
  }
});
